export function filterObjectKeys<T extends Record<string, any>>(
  object: T,
  callback: (value: any) => boolean,
  keysToCheck?: (keyof T)[]
) {
  const keys = Object.keys(object)
  const keysToSkip = keysToCheck ? keys.filter(key => !keysToCheck.includes(key)) : []
  const filteredKeys = keys
    .filter(key => !keysToSkip.includes(key))
    .filter(key => callback(object[key]))
  const entries = [...keysToSkip, ...filteredKeys].map(key => [key, object[key]])
  return Object.fromEntries(entries) as Partial<T>
}

export function objToKeyValString(obj): string {
  return Object.entries(obj)
    .map(pair => pair.join(" = "))
    .join(", ")
}

export function deepFreeze(obj) {
  Object.freeze(obj)

  if (obj === undefined) {
    return obj;
  }

  Object.getOwnPropertyNames(obj).forEach(function (prop) {
    if (obj[prop] !== null
      && (typeof obj[prop] === "object" || typeof obj[prop] === "function")
      && !Object.isFrozen(obj[prop])) {
      deepFreeze(obj[prop])
    }
  });

  return obj
}

export function sortObjectKeys(obj: Record<string, any>): Record<string, any> {
  return Object
    .keys(obj)
    .sort()
    .reduce((sorted, key) => {
      sorted[key] = obj[key]
      return sorted
    }, {}) as Record<string, any>
}