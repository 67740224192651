// IMPORTANT: don't assume that because one analytics system is present, others will be too!
// adblockers sometimes block one but not the other, so do your best to work with whatever is present

import supabase from "@/store/supabase-client"
import _ from 'lodash'
import { trackError, ErrorName } from '@/utils/track-error';

const ANONYMOUS_ANALYTICS = (window as any).ENV_ANONYMOUS_ANALYTICS as any
const RELAY_HOST = (window as any).ENV_RELAY_HOST as any;
const PING_RELAY_URL = RELAY_HOST + '/users/activity'
const PING_RELAY_THROTTLE = 1000 * 60 * 60

const pingRelay = _.throttle(async () => {
    try {
        const accountID = supabase.accountId
        const accountName = supabase.accountName
        const userID = supabase.userId
        const email = supabase.email
        if(!accountID || !accountName || !userID || !email) {
            return
        }

        await fetch(PING_RELAY_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                account_id: accountID,
                account_name: accountName,
                user_id: userID,
                email: email,
                has_token: false
            })
        })
    } catch(e) {
        console.log('Ping relay error', e)
    }
}, PING_RELAY_THROTTLE)

// these are getters because sometimes they're not initialized when this runs for the first time
export const getPosthog = () => (window as any).posthog as any;
const getSegment = () => (window as any).analytics as any;

export function trackPageView(path): void {
    if(ANONYMOUS_ANALYTICS)
        return;
    
    const accountId = supabase.accountId

    try {
        if (getPosthog()) {
            getPosthog().capture('$pageview');
        }
        if (getSegment()) {
            const options = accountId
              ? { context: { traits: { accountId } } }
              : undefined
            getSegment().page(path, options)
        }
    } catch (e) {
        console.log("could not capture page-event", e);
    }

    if(accountId) {
        pingRelay()
    }

    // add posthog support for spa
}

export function trackUser(userId: string, email?: string, accountName?: string, accountID?: string) {
    if(ANONYMOUS_ANALYTICS)
        return;
    
    let [emailUser, emailDomain] = ["unknown", "unknown"];
    if (email) {
        [emailUser, emailDomain] = email.split("@", 2);
    }

    const properties = {
        'email': email,
        'emailUser': emailUser,
        'username': email,
        'emailDomain': emailDomain,
        'userId': userId,
        'companyName': accountName,
        'companyID': accountID
    }

    try {
        if (getPosthog()) {
            getPosthog().identify(userId, properties);
            if (accountID && accountName) {
                getPosthog().group('company', accountID, {
                    'name': accountName,
                    'companyName': accountName,
                    'companyID': accountID
                });
            }
        }

        if (getSegment()) {
            if (typeof Intl !== 'undefined') {
              getSegment().identify(userId, {
                ...properties,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              });
            } else {
              getSegment().identify(userId, properties);
            }
            if (accountID && accountName) {
                getSegment().group(accountID, {
                    'companyName': accountName,
                    'companyID': accountID
                });
            }
        }

    } catch (e) {
        console.log("could not capture identify-event", e);
    }
}

// when we switch the active account for the same logged-in user
export function changeAccount(accountName: string, accountID?: string) {
    if(ANONYMOUS_ANALYTICS)
        return;
    
    const properties = {
        companyName: accountName,
        companyID: accountID
    }

    try {
        if (getPosthog()) {
            getPosthog().capture("Account change", {'$set': properties});
            getPosthog().group('company', accountID, properties);
        }
        if (getSegment()) {
            getSegment().group(accountID, properties);
        }
    } catch (e) {
        console.log("could not capture account-change event", e);
    }
}

export function trackEvent(eventName: string, properties?: Record<string, any>) {
    if(ANONYMOUS_ANALYTICS)
        return;

    const accountId = supabase.accountId

    try {
        if (getPosthog()) {
            getPosthog().capture(eventName, properties);
        }

        if (getSegment()) {
            const context = accountId
              ? { traits: { accountId } }
              : undefined
            getSegment().track(eventName, properties, context);
        }
    } catch (e) {
        console.log("could not capture event", e);
    }

    if(accountId) {
        pingRelay()
    }
}

function isTrackableUser(user) {
  return new Date(user.created_at).getTime() >= new Date('2024-05-28').getTime()
}

export async function trackUserSupabaseConnection() {
  if(!supabase.user || !isTrackableUser(supabase.user))
    return

  if(supabase.user.user_metadata.supabaseConnectionReported)
    return

  try {
    const response = await fetch(`${RELAY_HOST}/users/performed`, {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: supabase.userId,
        email: supabase.email,
        operation: 'UserSupabaseConnection'
      })
    })
    if(response.ok && response.status === 200) {
      supabase.client.auth.update({
        data: { supabaseConnectionReported: true }
      })
    }
  } catch(e) {
    trackError(ErrorName.FAILED_TO_TRACK_USER_SUPABASE_CONNECTION)
    console.error(e)
  }
}

export async function trackUserAccountConnection() {
  if(!supabase.user || !isTrackableUser(supabase.user))
    return
  
  if(supabase.userSettings.accountConnectionReported)
    return

  try {
    const response = await fetch(`${RELAY_HOST}/users/performed`, {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        account_id: supabase.accountId,
        account_name: supabase.accountName,
        user_id: supabase.userId,
        email: supabase.email,
        operation: 'UserAccountConnection'
      })
    })
    if(response.ok && response.status === 200) {
      supabase.setUserSetting('accountConnectionReported', true)
    }
  } catch(e) {
    trackError(ErrorName.FAILED_TO_TRACK_USER_ACCOUNT_CONNECTION)
    console.error(e)
  }
}

export async function trackUserClusterHolmesEnabled() {
  if(!supabase.user || !isTrackableUser(supabase.user))
    return
  
  if(supabase.userSettings.clusterHolmesEnabledReported)
    return

  try {
    const response = await fetch(`${RELAY_HOST}/users/performed`, {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        account_id: supabase.accountId,
        account_name: supabase.accountName,
        user_id: supabase.userId,
        email: supabase.email,
        operation: 'UserClusterHolmesEnabled'
      })
    })
    if(response.ok && response.status === 200) {
      supabase.setUserSetting('clusterHolmesEnabledReported', true)
    }
  } catch(e) {
    trackError(ErrorName.FAILED_TO_TRACK_USER_CLUSTER_HOLMES_ENABLED)
    console.error(e)
  }
}

export async function trackUserAccountData() {
  if(!supabase.user || !isTrackableUser(supabase.user))
    return
  
  if(supabase.userSettings.accountDataReported)
    return

  try {
    const response = await fetch(`${RELAY_HOST}/users/performed`, {
      method: 'POST',
      headers: {
        'Access-Control-Request-Method': 'POST',
        'Access-Control-Request-Headers': 'Content-Type',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        account_id: supabase.accountId,
        account_name: supabase.accountName,
        user_id: supabase.userId,
        email: supabase.email,
        operation: 'UserAccountData'
      })
    })
    if(response.ok && response.status === 200) {
      supabase.setUserSetting('accountDataReported', true)
    }
  } catch(e) {
    trackError(ErrorName.FAILED_TO_TRACK_USER_ACCOUNT_DATA)
    console.error(e)
  }
}
