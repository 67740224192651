import { ref } from 'vue'
import router from "@/router";
import { useUserAgent } from "@/composables/use-user-agent";
import { getAuthAndRunAction } from "@/manual-actions/helpers";
import { IS_SELF_HOSTED } from '@/consts';

import { trackError, ErrorName } from "@/utils/track-error";

const { getBrowserType, getOS } = useUserAgent()
const RELAY_HOST = (window as any).ENV_RELAY_HOST as any;

export interface IPageRenderLabel {
  page_name: string
  browser: ReturnType<typeof getBrowserType>
  os: ReturnType<typeof getOS>
}

interface ITechnicalErrorsLabel {
  page_name: string
  error_type: 'exception' | 'behavioral' | 'internal'
  error_name: string
}

export interface IManualActionQueryLabel {
  page_name: string
  action_name: string
  extra_info: string
}

export interface IDBQueryLabel {
  page_name: string
  table_name: string
  returned_records_count: number
  query_duration_hours: string
  filters: string
}

export interface IPageErrorsLabel {
  page_name: string
  browser: ReturnType<typeof getBrowserType>
  os: ReturnType<typeof getOS>
  error_name: ErrorName
}

export interface IMetric {
  account_id: string | undefined
  user_id: string | undefined

  name: 'db_query_ms' | 'manual_actions_query_ms' | 'technical_errors' | 'page_render_ms' | 'page_errors',
  value: number

  labels: IPageRenderLabel | IManualActionQueryLabel | IDBQueryLabel | ITechnicalErrorsLabel | IPageErrorsLabel
}

const collectedMetrics = ref<IMetric[]>([])

export function useMetricCollector () {
  function addMetric(metric: {
    account_id: string | undefined;
    user_id: string | undefined;
    name: string;
    value: number;
    labels: Omit<IManualActionQueryLabel, "page_name">
      | Omit<IDBQueryLabel, "page_name">
      | Omit<IPageRenderLabel, 'page_name'>
      | Omit<ITechnicalErrorsLabel, 'page_name'>
      | Omit<IPageErrorsLabel, 'page_name'>
  }) {
    if(IS_SELF_HOSTED)
      return
    router.onReady(() => {
      const metricPayload = {
        ...metric,
        labels: {
          ...metric.labels,
          page_name: router.currentRoute.name as string
        }
      } as IMetric

      collectedMetrics.value.push(metricPayload)
    })
  }

  async function sendMetric () {
    if(!collectedMetrics.value.length)
      return
    try {
      const endpoint = '/api/metrics'
      const url = RELAY_HOST + endpoint

      await getAuthAndRunAction((accountId, sessionToken) => {
        return fetch(url, {
          method: "POST",
          headers: {
            "Access-Control-Request-Method": "POST",
            "Access-Control-Request-Headers": "Content-Type",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            account_id: accountId,
            session_token: sessionToken,
            metrics: collectedMetrics.value
          }),
          keepalive: true
        })
      })

      collectedMetrics.value = []
    } catch (e) {
      console.log(e)
      trackError(ErrorName.FAILED_TO_SEND_METRIC);
    }
  }

  function initGathering () {
    setInterval(() => {
      sendMetric()
    }, 1000 * 60)
  }

  function beforeUnloadListener () {
    sendMetric()
  }

  return {
    addMetric,
    initGathering,
    beforeUnloadListener
  }
}
