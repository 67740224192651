import Vue, { set } from 'vue'
import { useStorage } from '@vueuse/core'
import { createClient, SupabaseClient, User } from "@supabase/supabase-js"
import { definitions } from "./supabase-autogenerated"
import { trackUser, changeAccount, trackUserSupabaseConnection, trackUserAccountConnection, trackUserAccountData, trackUserClusterHolmesEnabled } from "@/analytics"
import { AccountRequest, fetchUserAccounts, fetchUserType, fetchAllUserRequests } from "@/store/account/data"
import { UserType } from "@/store/account/types"
import { fetchClustersStatuses } from "@/store/cluster/data";
import { liveClusters, deadClusters } from "@/store/dead-clusters";
import { fetchAccountGraphs } from '@/graph-recommendations/data'
import { overrideQueryGraphs } from '@/store/override-query-graphs'
import { isClusterAlive } from "@/store/cluster/composition";
import { permissionActions, userAuthorizedActions, userType } from '@/store/rbac/user-permissions/store'
import { getPermissionActions } from "@/store/rbac/permissions-metadata/data"
import { getUserAuthorizedActions } from "@/store/rbac/user-permissions/data"
import { UserSettings, AccountSettings } from '@/store/user-settings/types'
import { fetchUserSettings, fetchAllUserSettings, updateUserSettings, fetchAccountSettings, updateAccountSettings } from '@/store/user-settings/data'
import { AccountSetupService } from "@/application/services/account-setup.service"
import { AuthService } from "@/application/services/auth.service"
import { fetchSingleUserAccount } from '@/store/single-user-account/data'
import { LimitedAccountData } from '@/store/single-user-account/types'
import { useAccountStore } from "@/application/store/account.store"
import { trackError, ErrorName } from '@/utils/track-error';
import { jwtDecode } from 'jwt-decode'
import router from '@/router'
import { RouteName } from '@/router/router-builder'

const SUPABASE_URL = (window as any).ENV_SUPABASE_URL as any
const SUPABASE_KEY = (window as any).ENV_SUPABASE_KEY as any
const DEFAULT_USER = (window as any).ENV_DEFAULT_USER as any
const DEFAULT_PASSWORD = (window as any).ENV_DEFAULT_PASSWORD as any

const { update: updateAccountStore } = useAccountStore()

export type Account = definitions["Accounts"]

class ReactiveSupabase {
  client: SupabaseClient
  disableLoading = false
  loading = false
  user: User | undefined
  invitations: AccountRequest[] = []
  accounts: Account[] = []
  activeAccount: Account | undefined
  savedActiveUserAccounts = useStorage<Record<string, string>>('savedActiveUserAccounts', {})
  setupCompleted = false
  userSettings: UserSettings = {}
  allUserSettings: UserSettings[] = []
  accountSettings: AccountSettings = {}
  limitedAccountData: LimitedAccountData | undefined

  constructor() {
    this.client = createClient(SUPABASE_URL, SUPABASE_KEY)

    if(DEFAULT_USER && DEFAULT_PASSWORD) {
      if(this.client.auth.user())
        return
      AuthService.loginWithEmail(DEFAULT_USER, DEFAULT_PASSWORD)
    }
  }

  async isAuthenticated() {
    if(this.client.auth.user())
      return true
    const isOAuthRedirect = window.location.hash.startsWith('#access_token=')
    const hasCredentials = () => window.localStorage.getItem('supabase.auth.token') !== null
    if(isOAuthRedirect || hasCredentials()) {
      return await new Promise<boolean>((resolve) => {
        const stop = () => {
          window.clearInterval(interval)
          subscription?.unsubscribe()
        }
        const interval = window.setInterval(() => {
          if(!isOAuthRedirect && !hasCredentials()) {
            resolve(false)
            stop()
          }
        }, 2000)
        const { data: subscription } = this.client.auth.onAuthStateChange((event, session) => {
          if((event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') && session) {
            resolve(true)
            stop()
          }
          else {
            resolve(false)
            stop()
          }
        })
      })
    }
    return false
  }

  get sessionId() {
    return jwtDecode<any>(this.client.auth.session()!.access_token).session_id as string
  }

  async loadAccounts() {
    const accountsResponse = await fetchUserAccounts()
    this.accounts = accountsResponse
      .sort((a, b) => new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime())
  }

  async loadInvitations() {
    const invitationsResponse = await fetchAllUserRequests()
    this.invitations = invitationsResponse
      .filter((inv) => inv.email.toLowerCase() == this.email.toLowerCase() && inv.status == 'PENDING')
  }

  async loadUserSettings() {
    try {
      [this.userSettings, this.allUserSettings] = await Promise.all([
        fetchUserSettings(this.userId, this.accountId),
        fetchAllUserSettings(this.userId)
      ])
    } catch(err) {
      trackError(ErrorName.FAILED_TO_SUPABASE_LOAD_USER_SETTINGS);
      console.error(err)
    }
  }

  async setUserSetting<K extends keyof UserSettings>(name: K, newValue: UserSettings[K]) {
    try {
      await updateUserSettings(this.userId, this.accountId, {
        ...this.userSettings,
        [name]: newValue
      })
      await this.loadUserSettings()
    } catch(err) {
      trackError(ErrorName.FAILED_TO_SUPABASE_SET_USER_SETTING);
      console.error(err)
    }
  }

  async loadAccountSettings() {
    try {
      this.accountSettings = await fetchAccountSettings(this.accountId)
    } catch(err) {
      console.error(err)
    }
  }

  async setAccountSetting<K extends keyof AccountSettings>(name: K, newValue: AccountSettings[K]) {
    try {
      await updateAccountSettings(this.accountId, {
        ...this.accountSettings,
        [name]: newValue
      })
      await this.loadAccountSettings()
    } catch(err) {
      console.error(err)
    }
  }

  async setUser(user: User) {
    if(this.user?.id === user.id)
      return

    if(!this.disableLoading)
      this.loading = true

    this.user = user

    const [accountsResponse, invitationsResponse] = await Promise.allSettled([
      this.loadAccounts(),
      this.loadInvitations()
    ])
    if([accountsResponse, invitationsResponse].some((response) => response.status === 'rejected')) {
      if(accountsResponse.status === 'rejected') {
        trackError(ErrorName.FAILED_TO_SUPABASE_LOAD_ACCOUNTS)
        console.log(accountsResponse.reason)
      }
      if(invitationsResponse.status === 'rejected') {
        trackError(ErrorName.FAILED_TO_SUPABASE_LOAD_INVITATIONS)
        console.log(invitationsResponse.reason)
      }
      this.loading = false
      router.push({
        name: RouteName.Error,
        params: { errorType: 'FAILED_TO_SET_USER' }
      })
      return
    }

    trackUser(user.id, user.email, this.accountName, this.accountId)
    trackUserSupabaseConnection()

    this.loading = false
  }

  async setActiveAccount(account: Account) {
    if(this.activeAccount?.id === account.id)
      return

    if(!this.disableLoading)
      this.loading = true

    this.activeAccount = account
    set(this.savedActiveUserAccounts, this.userId, account.id)

    const [
      userTypeResponse,
      setupCompletedResponse,
      limitedAccountDataResponse,
      clustersResponse,
      overrideQueryGraphsResponse
    ] = await Promise.allSettled([
      fetchUserType(account.id),
      AccountSetupService.isSetupCompleted(account.id),
      fetchSingleUserAccount(account.id),
      fetchClustersStatuses(account.id, { filterDead: false }),
      fetchAccountGraphs(account.id, { overrideOnly: true }),
      updateAccountStore(), // responseless
      this.loadUserSettings(), // responseless
      this.loadAccountSettings() // responseless
    ])
    if([userTypeResponse, setupCompletedResponse, limitedAccountDataResponse, clustersResponse].some((response) => response.status === 'rejected')) {
      this.loading = false
      router.push({
        name: RouteName.Error,
        params: { errorType: 'FAILED_TO_SET_ACCOUNT' }
      })
      return
    }
    if(userTypeResponse.status === 'fulfilled')
      userType.value = userTypeResponse.value
    if(setupCompletedResponse.status === 'fulfilled')
      this.setupCompleted = setupCompletedResponse.value
    if(limitedAccountDataResponse.status === 'fulfilled')
      this.limitedAccountData = limitedAccountDataResponse.value
    if(clustersResponse.status === 'fulfilled') {
      liveClusters.value = clustersResponse.value
        .filter((cluster) => isClusterAlive(cluster))
        .map(({ cluster_id }) => cluster_id)
      deadClusters.value = clustersResponse.value
        .filter((cluster) => !isClusterAlive(cluster))
        .map(({ cluster_id }) => cluster_id)
    }
    if(overrideQueryGraphsResponse.status === 'fulfilled')
      overrideQueryGraphs.value = overrideQueryGraphsResponse.value

    if(userType.value === UserType.RBAC) {
      const [
        permissionActionsResponse,
        userAuthorizedActionsResponse
      ] = await Promise.allSettled([
        getPermissionActions(),
        getUserAuthorizedActions(account.id)
      ])
      if(permissionActionsResponse.status === 'fulfilled')
        permissionActions.value = permissionActionsResponse.value
      if(userAuthorizedActionsResponse.status === 'fulfilled')
        userAuthorizedActions.value = userAuthorizedActionsResponse.value
    }

    changeAccount(account.name, account.id)
    trackUserAccountConnection().then(() => {
      if(this.setupCompleted) {
        trackUserAccountData()

        if(clustersResponse.status === 'fulfilled') {
          const holmesEnabled = clustersResponse.value
            .filter((cluster) => isClusterAlive(cluster))
            .some(cluster => cluster.activity_stats?.holmesEnabled)

          if(holmesEnabled)
            trackUserClusterHolmesEnabled()
        }
      }
    })

    this.loading = false
  }

  setLoadingDisable(value: boolean) {
    this.disableLoading = value
  }

  get loggedIn() {
    return this.user !== undefined
  }

  get userId() {
    return <string>this.user?.id
  }

  get email() {
    return <string>this.user?.email
  }

  get accountId() {
    return <string>this.activeAccount?.id
  }

  get accountName() {
    return <string>this.activeAccount?.name
  }
}

const reactiveSupabase = Vue.observable(new ReactiveSupabase())

export default reactiveSupabase

export function parseSupabaseDate(datestring: string) : Date {
  // to be honest we probably just have to add Z at the end... not sure if supabase ever returns the date
  // with a timezone right now
  return new Date(datestring.endsWith("Z") ? datestring : datestring + "Z");
}
